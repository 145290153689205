import React, { useState, useEffect } from 'react'
import Layout from '../../Shared/Layout/LayoutAdmin';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AlertDismissible from '../../Shared/Components/AlertDismissible';
import { Link, usePage } from '@inertiajs/inertia-react';
import { Inertia } from '@inertiajs/inertia';
import ActionsMenu from '../../Shared/Components/ActionsMenu';
import PaginationList from '../../Shared/Components/PaginationList';
import Breadcrumbs from '../../Shared/Components/Breadcrumbs';

const List = ({ items }) => {
    const { flash } = usePage().props
    const data = items.data;

    const query = new URL(window.location).searchParams;
    const [policy, setPolicy] = useState(query.get("polipolizacy") || '');
    const [status, setStatus] = useState(query.get("estatus") || '');
    const [contractor, setContractor] = useState(query.get("contratante") || '');
    const [insured, setInsured] = useState(query.get("asegurado") || '');

    useEffect(() => {
        setPolicy(query.get("poliza") || '');
        setStatus(query.get("estatus") || '');
        setContractor(query.get("contratante") || '');
        setInsured(query.get("asegurado") || '');
    }, []);

    function handleChangePolicy(e) {
        setPolicy(e.target.value);
    }
    function handleChangeStatus(e) {
        setStatus(e.target.value);
    }
    function handleChangeContractor(e) {
        setContractor(e.target.value);
    }
    function handleChangeInsured(e) {
        setInsured(e.target.value);
    }

    function search() { // search
        Inertia.get(route('insurance.application.list'),
            {
                poliza: policy,
                estatus: status,
                contratante: contractor,
                asegurado: insured,
            },
            {
                preserveState: true,
                preserveScroll: true,
                replace: true
            }
        )
    }

    return (
        <Row>
            <div className="mb-7 mt-7">
                <Breadcrumbs currentPath="/admin/quotes/applications" />
            </div>
            <Col className="mb-2 px-1">
                <h2>Listado de Solicitudes</h2>
            </Col>
            {flash.success && (
                <AlertDismissible message={flash.success} type="success" />
            )}
            {flash.error && (
                <AlertDismissible message={flash.error} type="danger" />
            )}

            <Row className="mb-4 px-1">
                <div className="d-flex justify-content-between px-0">
                    <div className="d-flex justify-content-around h-auto">
                        <Form.Control value={policy} placeholder='Num de Póliza' type="text" onChange={handleChangePolicy} className="mx-2" />
                        {/* <Form.Control value={status} placeholder='Estatus de Solicitud' type="text" onChange={handleChangeStatus} className="mx-2" /> */}
                        <Form.Select className="form-control" value={status} onChange={handleChangeStatus}>
                            <option value={''}></option>
                            <option value={'created'}>Creada</option>
                            <option value={'rejected'}>Rechazada</option>
                            <option value={'evaluation'}>Evaluación</option>
                            <option value={'requirement'}>Requisito</option>
                            <option value={'emission'}>Emitido</option>
                        </Form.Select>
                        <Form.Control value={contractor} placeholder='Contratante' type="text" onChange={handleChangeContractor} className="mx-2" />
                        <Form.Control value={insured} placeholder='Asegurado' type="text" onChange={handleChangeInsured} className="mx-2" />
                        <Button onClick={search} variant="primary" className='me-3 btn-sm mx-2' type="submit" >Filtrar</Button>
                    </div>
                    <div className="mx-0 h-auto">
                        <ActionsMenu>
                            <div className="text-md-center px-7 py-5 ">
                                <div className="mb-4">
                                    <Link href={route('policy-application.import.create')}>Carga/Creación de Polizas</Link>
                                </div>
                                <div className="mb-4">
                                    <Link href={route('policy-application.import_text.create')}>Carga/Creación de Polizas desde Txt</Link>
                                </div>
                                <div className="mb-4">
                                    <Link href={route('insurance.application.new')}>Agregar Solicitud</Link>
                                </div>
                                <div className="mb-4">
                                    <a href={route('insurance.application.generate.oii')}>Generar Archivo OII</a>
                                </div>
                                <div className="mb-4">
                                    <a href={route('insurance.application.import.oii')}>Carga de Archivo OII</a>
                                </div>
                            </div>
                        </ActionsMenu>
                    </div>
                </div>
            </Row>

            <div className="card card-flush h-md-100">
                <div className="card-body pt-6">
                    <div className="table-responsive">
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">

                            <thead>
                                <tr className="fs-7 fw-bold text-gray-400 border-bottom-0">
                                    <th className="p-0 pb-3 min-w-175px text-start">NÚMERO DE FOLIO</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">ASESOR/EMPLEADO</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">CONTRATANTE</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">ASEGURADO</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">ESTATUS</th>
                                    <th className="p-0 pb-3 min-w-175px text-start">OII</th>
                                    <th className="p-0 pb-3 w-50px text-end"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {data.length === 0 && (
                                    <tr>
                                        <td
                                            className="px-6 py-3 text-center text-gray-800 fw-bold mb-1 fs-6"
                                            colSpan="6"
                                        >
                                            No hay datos.
                                        </td>
                                    </tr>
                                )}
                                {data.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.application_number}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.asesor.user?.name} {item.asesor.lastname_ap} {item.asesor.lastname_am}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.contractor_name+" "+item.contractor_last_name+" "+item.contractor_mother_last_name}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.coverage_name+" "+item.coverage_lastname_paternal+" "+item.coverage_lastname_maternal}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.status}</td>
                                            <td className="text-gray-800 fw-bold mb-1 fs-6">{item.oii}</td>
                                            <td>
                                                <div className="d-flex justify-content-around">
                                                    <div className='mx-2'>
                                                        <Link href={route("insurance.application.edit", item.id)}>Editar</Link>
                                                    </div>
                                                    {/* <div className='mx-2'>
                                                        <Link className='text-danger' href={route("roles.delete", item.id)}>Eliminar</Link>
                                                    </div> */}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <PaginationList links={items.links} />
                </div>
            </div>
        </Row>
    );
}

List.layout = page => <Layout children={page} title="Solicitudes" />

export default List
